// extracted by mini-css-extract-plugin
export var content = "OurAddress--content--lsZQ3";
export var content__address = "OurAddress--content__address--muDL3";
export var content__button = "OurAddress--content__button--h4gDv";
export var content__locationName = "OurAddress--content__locationName--uUFeZ";
export var content__price = "OurAddress--content__price--+NIIY";
export var grid = "OurAddress--grid--O5Gxy";
export var grid__block = "OurAddress--grid__block--ovXxr";
export var image = "OurAddress--image--8sL9S";
export var imageLondon = "OurAddress--imageLondon--0LqKM";
export var title = "OurAddress--title--IKwul";
export var tkMyriadProSemiCondensed = "OurAddress--tk-myriad-pro-semi-condensed--HN0Nh";