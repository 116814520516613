import React from 'react';
import Img from 'gatsby-image';

import Button from 'components/common/button/Button';
import Markdown from 'components/common/markdown/Markdown';
import PriceWithoutVat from 'components/common/price-without-vat/PriceWithoutVat';
import { Props, OfficeProps } from 'components/directus/our-address/OurAddress.interface';
import SectionTitle from 'components/directus/section-title/SectionTitle';
import ourAddressHook from 'hooks/our-address/our-address.hook';
import { getFieldBySlug } from 'utils/directus/get-field-by-slug/get-field-by-slug';

import {
  grid__block,
  image as imageClass,
  imageLondon,
  content,
  content__locationName,
  content__address,
  content__price,
  content__button,
  grid,
} from './OurAddress.scss';

const Office = ({
  image,
  imageAlt,
  locationName,
  address,
  price,
  buyLink,
  london = false,
}: OfficeProps): JSX.Element => (
  <div className={grid__block}>
    <div>
      <Img
        fluid={image.localFile.childImageSharp.fluid}
        alt={imageAlt}
        className={`
                ${imageClass} 
                ${london ? imageLondon : ''}
                `}
        fadeIn
      />
    </div>

    <div className={content}>
      <h4 className={content__locationName}>{locationName}</h4>
      <Markdown source={address} className={content__address} container />
      <div className={content__price}>
        <PriceWithoutVat price={price} postText="per year" />
      </div>
      <div className={content__button}>
        <Button to={buyLink} role="primary">
          Buy Now
        </Button>
      </div>
    </div>
  </div>
);

export default ({ slug }: Props): JSX.Element => {
  const address = getFieldBySlug(slug, ourAddressHook()).node;

  return (
    <>
      <SectionTitle title={address.title} align="center" />

      <div className={grid}>
        <Office
          image={address.first_office_image}
          imageAlt={address.first_office_image_alt}
          locationName={address.first_office_title}
          address={address.first_office_body}
          price={address.first_office_price.value}
          buyLink={address.first_office_price.buy_link}
          london
        />

        <Office
          image={address.second_office_image}
          imageAlt={address.second_office_image_alt}
          locationName={address.second_office_title}
          address={address.second_office_body}
          price={address.second_office_price.value}
          buyLink={address.second_office_price.buy_link}
        />
      </div>
    </>
  );
};
