import React from 'react';
import { Helmet } from 'react-helmet';

import packagesHook from 'hooks/packages/packages.hook';
import servicesHook from 'hooks/services/services.hook';
import { getFieldBySlug } from 'utils/directus';

import { Props, Offer } from './Product.interface';

const ProductRichSnippet = ({ slug }: Props): JSX.Element => {
  const type = slug.indexOf('-package') > -1 ? 'Package' : 'Service';
  const snippet = {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    brand: {
      '@type': 'Brand',
      name: 'Rapid Formations',
    },
    name: '',
    description: '',
    image: '',
    offers: [] as Offer[],
  };

  if (type === 'Package') {
    const { node } = getFieldBySlug(slug, packagesHook());
    const { name, short_description, price, discounted_price, rich_snippet_image } = node;

    snippet.name = `${name} Package`;
    snippet.description = short_description;
    snippet.image = rich_snippet_image.data.full_url;
    snippet.offers.push({
      '@type': 'Offer',
      priceSpecification: {
        '@type': 'UnitPriceSpecification',
        price: discounted_price ? discounted_price.value.toString() : price.value.toString(),
        priceCurrency: 'GBP',
      },
    });
  } else {
    const { node } = getFieldBySlug(slug, servicesHook());
    const { title, description, image, services } = node;

    snippet.name = title;
    snippet.description = description;
    snippet.image = image.data.full_url;
    services.forEach((service) => {
      snippet.offers.push({
        '@type': 'Offer',
        priceSpecification: {
          '@type': 'UnitPriceSpecification',
          price: service.price.value.toString(),
          priceCurrency: 'GBP',
        },
      });
    });
  }

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(snippet)}</script>
    </Helmet>
  );
};

export default ProductRichSnippet;
