import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button from 'components/common/button/Button';
import Markdown from 'components/common/markdown/Markdown';
import PriceWithoutVat from 'components/common/price-without-vat/PriceWithoutVat';
import SectionTitle from 'components/directus/section-title/SectionTitle';
import purchaseAnAddressHook from 'hooks/purchase-an-address/purchase-an-address.hook';
import { getSolidFontAwesomeIcon } from 'utils/font-awesome/solid';
import { Props } from './PurchaseAnAddress.interface';

import {
  margin as marginClass,
  grid,
  block,
  block__header,
  block__header__iconCircle,
  block__header__icon,
  block__header__title,
  block__section,
  block__section__prices,
  block__section__price,
  block__section__location,
  block__section__button,
} from './PurchaseAnAddress.scss';

export default ({ slug, margin, title, columns }: Props): JSX.Element => {
  const addresses = purchaseAnAddressHook().filter(({ node }) => node.slug === slug);

  return (
    <div className={margin ? marginClass : null}>
      <SectionTitle title={title} align="center" />

      <div className={grid} style={{ gridTemplateColumns: `repeat(${columns || 3}, 1fr)` }}>
        {addresses.map(
          ({ node }, i): JSX.Element => (
            <div className={block} key={`purchase-an-address-${node.slug}-${i.toString(32)}`}>
              <div className={block__header}>
                <div className={block__header__iconCircle} style={{ backgroundColor: node.icon_colour }}>
                  <FontAwesomeIcon icon={getSolidFontAwesomeIcon(node.icon)} className={block__header__icon} />
                </div>
                <h3 className={block__header__title}>
                  <Markdown source={node.title} />
                </h3>
              </div>
              <div className={block__section}>
                <Markdown source={node.body} container />

                {node.first_office_price && node.second_office_price && (
                  <div className={block__section__prices}>
                    <div className={block__section__price}>
                      <h4 className={block__section__location}>{node.first_office_preface}:</h4>
                      <PriceWithoutVat price={node.first_office_price.value} postText={node.first_office_postface} />
                    </div>
                    <div className={block__section__price}>
                      <h4 className={block__section__location}>{node.second_office_preface}:</h4>
                      <PriceWithoutVat price={node.second_office_price.value} postText={node.second_office_postface} />
                    </div>
                  </div>
                )}

                <Button
                  to={node.button_link}
                  role="primary"
                  className={block__section__button}
                  style={{
                    backgroundColor: node.button_background_colour,
                    borderColor: node.button_background_colour,
                  }}
                >
                  {node.button_text}
                </Button>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};
