import { graphql, useStaticQuery } from 'gatsby';
import { AllDirectusServiceSchema, ServiceSchema } from './services.interface';

export default (): ServiceSchema[] => {
  const { allDirectusServicesSchema } = useStaticQuery<AllDirectusServiceSchema>(graphql`
    query {
      allDirectusServicesSchema {
        edges {
          node {
            directusId
            slug
            title
            description
            image {
              data {
                full_url
              }
            }
            services {
              directusId
              slug
              name
              type
              price {
                value
              }
            }
          }
        }
      }
    }
  `);

  return allDirectusServicesSchema.edges;
};
