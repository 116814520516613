// extracted by mini-css-extract-plugin
export var block = "PurchaseAnAddress--block--BFm1s";
export var block__header = "PurchaseAnAddress--block__header--mTujN";
export var block__header__icon = "PurchaseAnAddress--block__header__icon--qzu81";
export var block__header__iconCircle = "PurchaseAnAddress--block__header__iconCircle--c9wHZ";
export var block__header__title = "PurchaseAnAddress--block__header__title--u6Zvd";
export var block__section = "PurchaseAnAddress--block__section--Pqoh8";
export var block__section__button = "PurchaseAnAddress--block__section__button--CWt3v";
export var block__section__list = "PurchaseAnAddress--block__section__list--SZqNH";
export var block__section__location = "PurchaseAnAddress--block__section__location--7rSp4";
export var block__section__postface = "PurchaseAnAddress--block__section__postface--aF10l";
export var block__section__preface = "PurchaseAnAddress--block__section__preface--VIJT0";
export var block__section__price = "PurchaseAnAddress--block__section__price--+iSZM";
export var block__section__prices = "PurchaseAnAddress--block__section__prices--6tge4";
export var grid = "PurchaseAnAddress--grid--s4mI2";
export var margin = "PurchaseAnAddress--margin--NmSuK";
export var title = "PurchaseAnAddress--title--gtkUz";
export var tkMyriadProSemiCondensed = "PurchaseAnAddress--tk-myriad-pro-semi-condensed--2v03w";